import { O, R, S, flow, pipe } from "@mobily/ts-belt";
import { P, match } from "ts-pattern";

export type APIFormParserKey =
  | "pass"
  | "empty"
  | "notEmptyString"
  | "phone"
  | "mobilePhone"
  | "email"
  | "phoneOrEmail"
  | "validName"
  | "orderMethod"
  | "emptyOrEmail";

export type APIFormParserSet = [string, APIFormParserKey];
type APIFormParser = (value: string) => R.Result<string, string>;

const passParser: APIFormParser = (value) => R.Ok(value);

const validNameParser: APIFormParser = (value) =>
  match(value.trim())
    .with("", () => R.Error(value))
    .when(
      (v) => v.match(/[^a-zA-Z가-힣0-9\s\\(\\)]+/g),
      () => R.Error(value)
    )
    .with("Testing", R.Error)
    .otherwise(R.Ok);

const notEmptyStringParser: APIFormParser = (value) => {
  return pipe(
    value,
    S.trim,
    R.fromPredicate((v) => v !== "", value)
  );
};

const mobilePhoneParser: APIFormParser = (value) => {
  const parseNumberWithDash = (value: string) => {
    const cleanInput = value.replace(/[- ]/g, "");
    return match([cleanInput, cleanInput.length])
      .when(
        ([value]) => new RegExp(/[^-0-9]/).test(value),
        () => O.None
      )
      .with([P.string.startsWith("010"), P.union(11)], () =>
        O.Some(cleanInput.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"))
      )
      .with([P.string.startsWith("010"), P._], () => O.None)
      .with([P.string.startsWith("8210"), P.union(12)], () =>
        O.Some(cleanInput.replace(/(\d{4})(\d{4})(\d{4})/, "010-$2-$3"))
      )
      .with([P.string.startsWith("8210"), P._], () => O.None)
      .otherwise(() => O.None);
  };

  const parseWithDashResult = parseNumberWithDash(value);

  return match([parseWithDashResult])
    .returnType<R.Result<string, string>>()
    .with([P.not(P.nullish)], ([result]) => R.Ok(result))

    .otherwise(() => R.Error(value));
};

const phoneParser: APIFormParser = (value) => {
  const parseNumberWithDash = (value: string) => {
    const cleanInput = value.replace(/[- ]/g, "");
    return match([cleanInput, cleanInput.length])
      .when(
        ([value]) => new RegExp(/[^-0-9]/).test(value),
        () => O.None
      )
      .with([P.string.startsWith("02"), P.union(9, 10)], () =>
        O.Some(cleanInput.replace(/(\d{2})(\d{3,4})(\d{4})/, "$1-$2-$3"))
      )
      .with([P.string.startsWith("02"), P._], () => O.None)
      .with([P.string.startsWith("010"), P.union(11)], () =>
        O.Some(cleanInput.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"))
      )
      .with([P.string.startsWith("010"), P._], () => O.None)
      .with([P.string.startsWith("8210"), 12], () =>
        O.Some(cleanInput.replace(/(\d{4})(\d{4})(\d{4})/, "010-$2-$3"))
      )
      .with([P.string.startsWith("8210"), P._], () => O.None)
      .with([P.union(P.string.startsWith("0")), P.union(10, 11)], () =>
        O.Some(cleanInput.replace(/(\d{3})(\d{3,4})(\d{4})/, "$1-$2-$3"))
      )
      .with([P._, P.union(10, 11)], () => O.None)
      .with([P.string.startsWith("1"), 8], () =>
        O.Some(cleanInput.replace(/(\d{4})(\d{4})/, "$1-$2"))
      )
      .otherwise(() => O.None);
  };

  const parseWithDashResult = parseNumberWithDash(value);

  return match([parseWithDashResult])
    .returnType<R.Result<string, string>>()
    .with([P.not(P.nullish)], ([result]) => R.Ok(result))
    .otherwise(() => R.Error(value));
};

const emailParser: APIFormParser = (value) =>
  pipe(
    value,
    R.fromPredicate((x) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(x), value)
  );

const emptyParser: APIFormParser = (value) =>
  value === "" ? R.Ok(value) : R.Error(value);

const or =
  (x: APIFormParser, y: APIFormParser): APIFormParser =>
    (value: string) => {
      return match(value)
        .when(flow(x, R.isOk), x)
        .when(flow(y, R.isOk), y)
        .otherwise(() => R.Error(value));
    };

export const orderMethodParser: APIFormParser = (value) => {
  return match(value)
    .with(
      P.union(
        "카카오/전화/문자",
        "수발주 전산 프로그램",
        "주문을 받고 있지 않음"
      ),
      (value) => R.Ok(value)
    )
    .otherwise(() => R.Error(value));
};

// const and =
//   (x: APIFormParser, y: APIFormParser): APIFormParser =>
//     (value: string) => {
//       return pipe(x(value), R.flatMap(y));
//     };

export const FormParseSet: Record<
  APIFormParserKey,
  (x: string) => R.Result<string, string>
> = {
  pass: passParser,
  validName: validNameParser,
  notEmptyString: notEmptyStringParser,
  phone: phoneParser,
  mobilePhone: mobilePhoneParser,
  email: emailParser,
  phoneOrEmail: or(phoneParser, emailParser),
  orderMethod: orderMethodParser,
  emptyOrEmail: or(emptyParser, emailParser),
  empty: emptyParser,
} as const;
