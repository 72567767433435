import { A, pipe } from "@mobily/ts-belt";
import React, { type CSSProperties } from "react";
import { match } from "ts-pattern";
import styles from "./BoldableText.module.css";
import { asx } from "./util/applyStyle";
const as = asx(styles);
type fontWeight = "normal" | "bold";
export type BoldableTextAlign = "center" | "left" | "right";

export const normalText = (text: string): BoldableText => ({
  text,
  fontWeight: "normal" as const,
  highlighted: false,
});

export const boldText = (text: string): BoldableText => ({
  text,
  fontWeight: "bold" as const,
  highlighted: false,
});

export const highlightedText = (text: string): BoldableText => ({
  text,
  fontWeight: "bold" as const,
  highlighted: true,
});

export type BoldableText = {
  text: string;
  fontWeight: fontWeight;
  highlighted: boolean;
  style?: CSSProperties | undefined;
};

export const BoldableText = ({
  text,
  fontWeight,
  highlighted,
  style,
}: BoldableText) => {
  return match(fontWeight)
    .with("normal", () => (
      <span className={as("normal")} style={style}>
        {text}
      </span>
    ))
    .with("bold", () => (
      <strong
        className={as("bold") + (highlighted ? "" : "")}
        key={text}
        style={{
          color: `${highlighted ? "#fe6b37" : "inherit"}`,
          ...style,
        }}
      >
        {text}
      </strong>
    ))
    .exhaustive();
};

export type BoldableTextListProps = {
  list: BoldableText[];
};

export const BoldableTextList = ({ list }: BoldableTextListProps) => {
  return (
    <>
      {list.map((_txt) => (
        <BoldableText
          text={_txt.text}
          fontWeight={_txt.fontWeight}
          highlighted={_txt.highlighted}
          style={_txt.style}
          key={_txt.text}
        />
      ))}
    </>
  );
};

export type BoldableTextMultilineProps = {
  list: BoldableText[][];
  align: "center" | "left" | "right";
  size?: number;
  style?: CSSProperties | undefined;
};

export const BoldableTextMultiline = ({
  list,
  align,
  style,
  size,
}: BoldableTextMultilineProps) => {
  return (
    <div
      style={{
        textAlign: align,
        width: "max-content",
        fontSize: size ?? "inherit",
        ...style,
      }}
    >
      {pipe(
        list.map((설명_줄) =>
          설명_줄.map((_txt, i) => (
            <BoldableText
              text={_txt.text}
              fontWeight={_txt.fontWeight}
              highlighted={_txt.highlighted}
              style={_txt.style}
              key={_txt.text + i}
            />
          ))
        ),
        A.map((x) => [...x, <br key={Math.random()} />])
      )}
    </div>
  );
};
