import {
  type ChangeEventHandler,
  type FocusEventHandler,
  useState,
  useRef,
  type KeyboardEventHandler,
} from "react";
import { F, R, S, flow, pipe } from "@mobily/ts-belt";
import { FormParseSet, type APIFormParserKey } from "./api/apiForm";
import { P, match } from "ts-pattern";

type FormFieldFormatter = (value: string) => string;

export type FormFieldProps<T extends string> = {
  label: string;
  errorLabel: string;
  fieldName: T;
  validateFunction: APIFormParserKey;
  formatter?: FormFieldFormatter;
  onUpdate: (value: R.Result<string, string>) => void;
  triggerValidate?: boolean;
  placeholder: string;
  maxLength?: number;
  styles: {
    readonly [key: string]: string;
  };
  onEnter?: KeyboardEventHandler<HTMLInputElement>;
  className?: string | undefined;
};

export const phoneFormatter: FormFieldFormatter = (phoneNumer) => {
  const validInput = (phoneNumer || "").replace(/[^0-9|-]/g, "");
  const cleanInput = validInput.replace(/[-]/g, "");

  return pipe(
    R.fromExecution(() =>
      match([cleanInput, cleanInput.length])
        .with([P._, 8], () => cleanInput.replace(/(\d{4})(\d{4})/, "$1-$2"))
        .with([P.string.startsWith("02"), P.union(9, 10)], () =>
          cleanInput.replace(/(\d{2})(\d{3,4})(\d{4})/, "$1-$2-$3")
        )
        .with([P.not(P.string.startsWith("02")), P.union(10, 11)], () =>
          cleanInput.replace(/(\d{3})(\d{3,4})(\d{4})/, "$1-$2-$3")
        )
        .otherwise(() => validInput)
    ),
    R.getWithDefault(validInput),
    S.slice(0, 13)
  );
};

export const FormField = <T extends string>({
  label,
  errorLabel,
  fieldName,
  validateFunction,
  formatter = F.identity,
  onUpdate,
  placeholder,
  maxLength = 50,
  onEnter,
  styles,
}: FormFieldProps<T>) => {
  const [value, setValue] = useState<string>("");
  const error = useRef<boolean>(false);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    pipe(
      e.currentTarget.value,
      F.tap((newValue) => setValue(newValue)),
      formatter,
      FormParseSet[validateFunction],
      R.mapError(formatter),
      F.tap(flow(R.handleError(F.identity), R.getExn, setValue, F.ignore)),
      F.tap((result) => {
        error.current = R.isError(result);
      }),
      onUpdate
    );
  };

  const onBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    pipe(
      e.currentTarget.value,
      formatter,
      FormParseSet[validateFunction],
      F.tap(onUpdate),
      F.tap((result) => {
        error.current = R.isError(result);
      })
    );
  };

  return (
    <>
      <div className={`${styles["field-name"]}`}>{label}</div>
      <div
        className={`${styles["field-input"]} ${
          error.current ? styles["has-error"] : ""
        }`}
        id={`${fieldName}Field`}
      >
        <input
          className={`${styles["input"]} input`}
          type="text"
          name={fieldName}
          onInput={onChange}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={maxLength}
          placeholder={placeholder}
          value={value}
          onKeyDown={(e) => {
            match([onEnter, e.code])
              .with(
                [P.not(P.nullish), P.union("NumpadEnter", "Enter")],
                ([onEnter]) => {
                  onEnter(e);
                }
              )
              .otherwise(() => {});
          }}
          required
        />
        <div className={styles["field-error-info"]}>{errorLabel}</div>
      </div>
    </>
  );
};
